<template>
  <div>
    <input
      id="pac-input"
      class="controls"
      type="text"
      placeholder="Search Box"
    />
    <div id="map"></div>
  </div>
</template>

<script>
  import { Configuration } from '@/core/utils';

  export default {
    name: 'GooglePlaceSearch',
    data: () => ({
      location: null
    }),
    mounted() {
      // Reference https://dev.to/tareqnewazshahriar/how-to-add-google-map-api-script-in-a-vue-project-without-plugins-3ldi
      window.checkAndAttachMapScript = function (callback) {
        let scriptId = 'map-api-script';
        let mapAlreadyAttached = !!document.getElementById(scriptId);

        if (mapAlreadyAttached) {
          if (window.google)
            // Script attached but may not finished loading; so check for 'google' object.
            callback();
        } else {
          window.mapApiInitialized = callback;

          let script = document.createElement('script');
          let key = Configuration.value('googleMapsApiKey');

          script.id = scriptId;
          script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places,geometry&callback=mapApiInitialized`;
          document.body.appendChild(script);
        }

        return mapAlreadyAttached;
      };

      window.checkAndAttachMapScript(this.initAutocomplete);
    },
    methods: {
      initAutocomplete() {
        const map = new window.google.maps.Map(document.getElementById('map'), {
          center: { lat: 4.210484, lng: 101.975766 },
          zoom: 8,
          mapTypeId: 'roadmap'
        });
        // Create the search box and link it to the UI element.
        const input = document.getElementById('pac-input');
        const searchBox = new window.google.maps.places.SearchBox(input);
        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
        // Bias the SearchBox results towards current map's viewport.
        map.addListener('bounds_changed', () => {
          searchBox.setBounds(map.getBounds());
        });
        let markers = [];
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener('places_changed', () => {
          const places = searchBox.getPlaces();

          if (places.length == 0) {
            return;
          }

          let enteredRecord = places[0];

          let data = {
            address: enteredRecord.formatted_address,
            postcode: null,
            city: null,
            state: null,
            latitude: enteredRecord.geometry.location.lat(),
            longitude: enteredRecord.geometry.location.lng()
          };

          enteredRecord.address_components.forEach((x) => {
            if (x.types.find((y) => y == 'postal_code')) {
              data.postcode = x.long_name;
            }

            if (x.types.find((y) => y == 'administrative_area_level_1')) {
              data.state = x.long_name;
            }

            if (
              x.types.find((y) => y == 'locality') &&
              x.types.find((y) => y == 'political')
            ) {
              data.city = x.long_name;
            }
          });

          this.$emit('input', data);

          // Clear out the old markers.
          markers.forEach((marker) => {
            marker.setMap(null);
          });
          markers = [];
          // For each place, get the icon, name and location.
          const bounds = new window.google.maps.LatLngBounds();
          places.forEach((place) => {
            if (!place.geometry || !place.geometry.location) {
              console.log('Returned place contains no geometry');
              return;
            }
            const icon = {
              url: place.icon,
              size: new window.google.maps.Size(71, 71),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(17, 34),
              scaledSize: new window.google.maps.Size(25, 25)
            };
            // Create a marker for each place.
            markers.push(
              new window.google.maps.Marker({
                map,
                icon,
                title: place.name,
                position: place.geometry.location
              })
            );

            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          map.fitBounds(bounds);
        });
      },
      resetSearch() {
        const input = document.getElementById('pac-input');
        input.value = null;
      }
    }
  };
</script>

<style>
  #map {
    height: 100vh;
  }

  #description {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
  }

  #infowindow-content .title {
    font-weight: bold;
  }

  #infowindow-content {
    display: none;
  }

  #map #infowindow-content {
    display: inline;
  }

  .pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto;
  }

  #pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
  }

  .pac-controls {
    display: inline-block;
    padding: 5px 11px;
  }

  .pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
  }

  #pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
    height: 50px;
  }

  #pac-input:focus {
    border-color: #4d90fe;
  }

  #title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
  }

  #target {
    width: 345px;
  }
</style>
