<template>
  <SignupCard :title="$t('label.uploadDocumentsHere')">
    <div class="px-4 mb-6">
      <div class="font-weight-medium surface--text text-lg-body-1 px-2 mb-4">
        {{ $t('message.selectYourBusinessModelToUploadDocumentAsRequired') }}
      </div>

      <v-radio-group v-model="businessModel">
        <template v-for="(item, index) in businessModelOptions">
          <AppRadio :key="index" :value="item.key" class="my-6">
            <div class="pl-4 surface--text">
              <div class="font-weight-black text-body-2 text-lg-body-1">
                ({{ item.alphabet }}) {{ $t(`label.${item.type}`) }}
              </div>
              <div class="font-weight-regular text-caption text-lg-body-2">
                {{ $t(`label.${item.description}`) }}
              </div>
            </div>
          </AppRadio>
          <div
            v-if="index == 0"
            :key="`${index}-or`"
            class="surface--text font-weight-bold text-center text-uppercase"
          >
            {{ $t('label.or') }}
          </div>
        </template>
      </v-radio-group>

      <v-divider class="surface my-6"></v-divider>

      <div class="surface--text px-2">
        <div>*{{ $t('message.documentCanBeIn') }}</div>
        <div>*{{ $t('message.eachFileSizeNotLargeThan', [5]) }}</div>
      </div>
    </div>

    <v-row no-gutters class="surface--text">
      <template v-for="(item, index) in documentSlots">
        <v-col :key="index" cols="4" class="pa-2 pa-sm-4">
          <v-img
            :src="documentDisplayImage(item)"
            class="cursor-pointer mb-4"
            @click="selectDocumentToUploadAction(item)"
          ></v-img>
          <div
            class="surface--text font-weight-bold text-center text-capitalize"
          >
            {{ $t(`enumCreatorApplicationDocumentType.${item.value}`) }}
          </div>
          <div
            v-if="item.value == creatorApplicationDocumentType.HALAL_CERT"
            class="text-caption text-center"
          >
            ({{ $t('label.optional') }})
          </div>
        </v-col>
      </template>
    </v-row>

    <input
      type="file"
      id="fileInput"
      ref="fileInput"
      class="d-none"
      :accept="applicationDocumentAcceptFileType.toString()"
      @change="documentInputChange"
    />

    <div class="my-8 px-4">
      <div class="text-center mb-6">
        <SignupButton
          :incompleteInput="incompleteDocument"
          :isSuccessful="isSubmittingApplication"
          class="upload-document-card__action-button"
          @click="submitAction"
        >
          {{ $t('label.submit') }}
        </SignupButton>
      </div>
      <div class="text-center">
        <v-btn
          large
          rounded
          outlined
          color="surface"
          class="upload-document-card__action-button font-weight-bold px-8 secondary--text"
          @click="skipAction"
        >
          {{ $t('label.doItLater') }}
        </v-btn>
      </div>
    </div>
  </SignupCard>
</template>

<script>
  import common from '@/core/mixin/common.mixin';
  import SignupCard from '@/views/components/signup/SignupCard';
  import SignupButton from '@/views/components/signup/SignupButton';
  import { CreatorApplicationDocumentType } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import {
    CREATOR_APPLICATION_GET_APPLICATION,
    CREATOR_APPLICATION_UPLOAD_DOCUMENT,
    CREATOR_APPLICATION_SUBMIT_APPLICATION,
    CREATOR_APPLICATION_INITIAL_GET_APPLICATION_STATE,
    CREATOR_APPLICATION_INITIAL_UPLOAD_DOCUMENT_STATE,
    CREATOR_APPLICATION_INITIAL_SUBMIT_APPLICATION_STATE
  } from '@/core/store/creator-application.module';

  export default {
    name: 'UploadDocumentCard',
    components: {
      SignupCard,
      SignupButton
    },
    mixins: [common],
    data: () => ({
      creatorApplicationDocumentType: CreatorApplicationDocumentType,
      applicationObj: null,
      documents: [],
      applicationDocumentAcceptFileType: [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ],
      individualDocumentAvailableSlots: [
        CreatorApplicationDocumentType.IC_FRONT,
        CreatorApplicationDocumentType.IC_BACK,
        CreatorApplicationDocumentType.BANK_STATEMENT
      ],
      nonIndividualDocumentAvailableSlots: [
        CreatorApplicationDocumentType.DOCUMENT,
        CreatorApplicationDocumentType.BANK_STATEMENT,
        CreatorApplicationDocumentType.HALAL_CERT
      ],
      isSubmittingApplication: false,
      incompleteDocument: false,
      businessModelOptions: [
        {
          value: false,
          key: 'individual',
          alphabet: 'A',
          type: 'individual',
          description: 'creatorWithoutBusinessLicense'
        },
        {
          value: false,
          key: 'nonIndividualCorporate',
          alphabet: 'B',
          type: 'nonIndividualCorporate',
          description: 'businessCorporate'
        }
      ],
      businessModel: 'individual',
      selectedDocumentType: null
    }),
    computed: {
      getApplicationComplete() {
        return this.$store.state.creatorApplication.application.complete;
      },
      uploadDocumentComplete() {
        return this.$store.state.creatorApplication.uploadDocument.complete;
      },
      submitApplicationComplete() {
        return this.$store.state.creatorApplication.submitApplication.complete;
      },
      documentSlots() {
        let types = [];
        let modelOptions = {
          individual: 'individualDocumentAvailableSlots',
          nonIndividualCorporate: 'nonIndividualDocumentAvailableSlots'
        };

        this.constantsData.creatorApplicationDocumentType.forEach((x) => {
          this[modelOptions[this.businessModel]].forEach((y) => {
            if (x.value == y) {
              types.push(x);
            }
          });
        });

        return types;
      }
    },
    watch: {
      getApplicationComplete() {
        let response = this.$store.state.creatorApplication.application;

        if (response.complete) {
          this.getApplicationCompleteAction(response);
        }
      },
      uploadDocumentComplete() {
        let response = this.$store.state.creatorApplication.uploadDocument;

        if (response.complete) {
          this.uploadDocumentCompleteAction(response);
        }
      },
      submitApplicationComplete() {
        let response = this.$store.state.creatorApplication.submitApplication;

        if (response.complete) {
          this.submitApplicationCompleteAction(response);
        }
      },
      businessModel() {
        this.checkDocumentIsComplete();
      }
    },
    created() {
      this.initUploadDocumentCard();
    },
    methods: {
      documentDisplayImage(item) {
        let path = '/assets/image/icon_add-document.png';

        if (this.documents.length > 0) {
          if (this.documents.find((x) => x.documentType == item.value)) {
            path = '/assets/image/icon_folder.png';
          }
        }

        return path;
      },
      selectDocumentToUploadAction(data) {
        this.selectedDocumentType = data.value;
        this.inputDocument();
      },
      businessModelCheckboxInput(value) {
        this.businessModel = value;
        this.businessModelOptions.forEach((x) => {
          x.value = x.key == value;
        });
        this.businessModelOptions.find((x) => x.key != value).value = false;
      },
      submitApplicationCompleteAction(response) {
        if (response.code == 0) {
          this.$emit('is-successful');
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage('label.submitApplication');
          let description = response.message;
          let buttons = [
            {
              color: 'secondary',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ];
          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialSubmitApplicationState();
      },
      uploadDocumentCompleteAction(response) {
        let type = response.code == 0 ? 'success' : 'error';
        let title = i18nHelper.getMessage('label.uploadDocument');
        let description = response.message;
        let buttons = [
          {
            color: 'secondary',
            text: i18nHelper.getMessage('label.ok'),
            action: () => {
              this.closeAppDialogInfo();
              this.getApplication();
            }
          }
        ];

        this.openAppDialogInfo(type, title, description, buttons);
        this.initialUploadDocumentState();
      },
      getApplicationCompleteAction(response) {
        if (response.code == 0) {
          let data = response.data;

          this.applicationObj = data;
          this.documents = data.documents;
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage('label.getApplication');
          let description = response.message;
          let buttons = [
            {
              color: 'secondary',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ];
          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.checkDocumentIsComplete();
        this.initialGetApplicationState();
      },
      skipAction() {
        this.$emit('is-successful');
      },
      checkDocumentIsComplete() {
        let isComplete = true;
        let modelOptions = {
          individual: 'individualDocumentAvailableSlots',
          nonIndividualCorporate: 'nonIndividualDocumentAvailableSlots'
        };

        this[modelOptions[this.businessModel]].forEach((x) => {
          if (x != CreatorApplicationDocumentType.HALAL_CERT) {
            if (this.documents.findIndex((y) => y.documentType == x) == -1) {
              isComplete = false;
            }
          }
        });

        this.incompleteDocument = !isComplete;
      },
      submitAction() {
        let data = {
          isCorporate: this.businessModel == 'nonIndividualCorporate'
        };

        this.isSubmittingApplication = true;
        this.submitApplication(data);
      },
      documentInputChange(event) {
        let files = event.target.files[0];

        if (files) {
          let data = {
            file: files,
            type: this.selectedDocumentType
          };

          this.uploadDocument(data);
        }
      },
      inputDocument() {
        this.$refs.fileInput.click();
      },
      registerAction() {
        if (this.$refs.registerAccountForm.validate()) {
          this.signup(this.signUpObj);
        }
      },
      submitApplication(data) {
        this.$store.dispatch(CREATOR_APPLICATION_SUBMIT_APPLICATION, { data });
      },
      initialSubmitApplicationState() {
        this.$store.dispatch(
          CREATOR_APPLICATION_INITIAL_SUBMIT_APPLICATION_STATE
        );
      },
      uploadDocument(data) {
        this.$store.dispatch(CREATOR_APPLICATION_UPLOAD_DOCUMENT, { data });
      },
      initialUploadDocumentState() {
        this.$store.dispatch(CREATOR_APPLICATION_INITIAL_UPLOAD_DOCUMENT_STATE);
      },
      getApplication() {
        this.$store.dispatch(CREATOR_APPLICATION_GET_APPLICATION);
      },
      initialGetApplicationState() {
        this.$store.dispatch(CREATOR_APPLICATION_INITIAL_GET_APPLICATION_STATE);
      },
      initUploadDocumentCard() {
        this.getApplication();
      }
    }
  };
</script>

<style lang="scss">
  .upload-document-card__action-button {
    width: 40%;
  }

  @media (max-width: 1535px) {
    .upload-document-card__action-button {
      width: 45%;
    }
  }

  @media (max-width: 1335px) {
    .upload-document-card__action-button {
      width: 50%;
    }
  }

  @media (max-width: 1263px) {
    .upload-document-card__action-button {
      width: 55%;
    }
  }

  @media (max-width: 959px) {
    .upload-document-card__action-button {
      width: 40%;
    }
  }

  @media (max-width: 599px) {
    .upload-document-card__action-button {
      width: 60%;
    }
  }
</style>
