<template>
  <SignupCard :title="$t('label.almostThere')" class="register-account-card">
    <v-form v-model="isRegisterAccountFormValid" ref="registerAccountForm">
      <template v-for="(i, index) in formFields">
        <div :key="index">
          <div class="surface--text text-capitalize px-6 mb-2">
            {{ $t(`label.${i.label}`) }}
          </div>
          <AppFormField
            v-model="signUpObj[i.key]"
            :type="i.type"
            :solo="true"
            :flat="true"
            :rounded="true"
            :rules="
              i.key != 'passwordConfirmation'
                ? i.rules
                : formValidation.confirmPasswordRules(
                    signUpObj.password,
                    signUpObj[i.key]
                  )
            "
            :disabled="registerIsSuccessful"
            :allowShowPassword="true"
            autocapitalize="none"
            color="secondary"
            backgroundColor="text"
          >
          </AppFormField>
        </div>
      </template>
      <div class="px-4">
        <v-checkbox
          v-model="checkboxAgreement"
          :rules="formValidation.requiredRules()"
          :disabled="registerIsSuccessful"
          dark
          color="primary"
          class="register-account-card__checkbox"
          on-icon="mdi-check-circle"
          off-icon="mdi-check-circle"
        >
          <template v-slot:label>
            <div
              class="surface--text"
              @click.stop
              v-html="$t('message.agreementMessage')"
            ></div>
          </template>
        </v-checkbox>
      </div>
      <div class="text-center my-8">
        <SignupButton
          :incompleteInput="registerFormIncomplete"
          :isSuccessful="registerIsSuccessful"
          @click="registerAction"
        >
          {{ $t('label.submit') }}
        </SignupButton>
      </div>
    </v-form>
  </SignupCard>
</template>

<script>
  import common from '@/core/mixin/common.mixin';
  import SignupCard from '@/views/components/signup/SignupCard';
  import SignupButton from '@/views/components/signup/SignupButton';
  import { ROUTE_NAME } from '@/core/constants';
  import { formValidationHelper, i18nHelper } from '@/core/utils';
  import {
    AUTH_SIGNUP,
    AUTH_INITIAL_SIGNUP_STATE
  } from '@/core/store/auth.module';

  export default {
    name: 'RegisterAccountCard',
    components: {
      SignupCard,
      SignupButton
    },
    mixins: [common],
    props: ['phoneNumber'],
    data: () => ({
      formValidation: formValidationHelper,
      signUpObj: {
        // username: null,
        // email: null,
        password: null,
        passwordConfirmation: null,
        referralCode: null,
        contactNumber: null
      },
      formFields: [
        // {
        //   key: 'username',
        //   rules: formValidationHelper.usernameRules(),
        //   label: 'username'
        // },
        // {
        //   key: 'email',
        //   rules: formValidationHelper.emailRules(),
        //   label: 'email'
        // },
        {
          key: 'password',
          rules: formValidationHelper.passwordRules(),
          label: 'createPassword',
          type: 'password'
        },
        {
          key: 'passwordConfirmation',
          rules: formValidationHelper.confirmPasswordRules(),
          label: 'reTypePassword',
          type: 'password'
        },
        {
          key: 'referralCode',
          label: 'referralCode'
        }
      ],
      registerIsSuccessful: false,
      isRegisterAccountFormValid: false,
      checkboxAgreement: false
    }),
    computed: {
      signupComplete() {
        return this.$store.state.auth.signup.complete;
      },
      registerFormIncomplete() {
        return this.registerIsSuccessful
          ? false
          : !this.isRegisterAccountFormValid;
      }
    },
    watch: {
      signupComplete() {
        let response = this.$store.state.auth.signup;

        if (response.complete) {
          this.signupCompleteAction(response);
        }
      }
    },
    mounted() {
      this.checkIncomingQueryParams();
      this.insertHref();

      this.signUpObj.contactNumber = this.phoneNumber;
    },
    methods: {
      signupCompleteAction(response) {
        if (response.code == 0) {
          this.registerIsSuccessful = true;
          this.$emit('is-successful');
        } else {
          let title = i18nHelper.getMessage('label.signup');
          let description = response.message;

          let type = 'error';
          let buttons = [
            {
              color: 'secondary',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ];
          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialSignupState();
      },
      registerAction() {
        let data = this.signUpObj;
        // data.username = this.signUpObj.username.toLowerCase();

        if (this.$refs.registerAccountForm.validate()) {
          this.signup(data);
        }
      },
      signup(data) {
        this.$store.dispatch(AUTH_SIGNUP, { data });
      },
      initialSignupState() {
        this.$store.dispatch(AUTH_INITIAL_SIGNUP_STATE);
      },
      checkIncomingQueryParams() {
        let route = this.$route;

        if (route.query.referral) {
          this.signUpObj.referralCode = route.query.referral;
        }
      },
      insertHref() {
        let id = ['termsOfUse', 'privacyPolicy'];
        let link = {
          termsOfUse: ROUTE_NAME.TERMS_OF_USE,
          privacyPolicy: ROUTE_NAME.PRIVACY_POLICY
        };

        id.forEach((x) => {
          let element = document.getElementById(x);
          element.setAttribute('href', `/support/${link[x]}`);
          element.setAttribute('target', '_blank');
          element.setAttribute('class', 'surface--text');
        });
      }
    }
  };
</script>

<style lang="scss">
  .register-account-card {
    .register-account-card__checkbox {
      &.v-input--is-label-active {
        &.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate)
          .v-icon {
          color: var(--v-primary-base) !important;
        }
      }
      .v-input--selection-controls__input {
        margin-right: 28px !important;
        .v-icon {
          font-size: 30px !important;
        }
      }
      .v-label {
        font-size: 0.875rem !important;
        font-weight: 700 !important;
        color: var(--v-surface-base) !important;
        line-height: 16px !important;
      }
    }
  }
</style>
