<template>
  <PageLayout
    bannerSrc="/assets/image/sign-up_background.jpg"
    :pageTitle="$t('label.shareYourCookingJourney')"
    :pageSubtitle="$t('label.beOurCreatorPartner')"
    :hideLogo="true"
    pageTitleDividerClass="primary"
    layoutType="b"
  >
    <div class="content-wrapper pt-8 pb-12 px-4">
      <div class="surface--text font-weight-bold text-h5 px-md-12 pb-8">
        {{ $t('label.signup') }}
      </div>
      <v-scroll-x-transition leave-absolute>
        <v-row
          v-if="!setContactNumberIsSuccessful"
          no-gutters
          justify="space-between"
        >
          <v-col cols="12" md="6" xl="5" class="pr-md-6 mb-6 mb-md-0">
            <SetMobileNumberCard
              @is-successful="accountRegistrationIsSuccessful = true"
              v-on:contactNumber="updateContactNumber"
            ></SetMobileNumberCard>
          </v-col>
          <v-scroll-x-transition leave-absolute>
            <v-col
              v-if="accountRegistrationIsSuccessful"
              cols="12"
              md="6"
              xl="5"
              class="pl-md-6"
            >
              <RegisterAccountCard
                @is-successful="setContactNumberIsSuccessful = true"
                :phoneNumber="this.phoneNumber"
              ></RegisterAccountCard>
            </v-col>
          </v-scroll-x-transition>
        </v-row>
      </v-scroll-x-transition>

      <v-scroll-x-transition leave-absolute>
        <v-row
          v-if="setContactNumberIsSuccessful"
          no-gutters
          justify="space-between"
        >
          <v-col cols="12" md="6" xl="5" class="pr-md-6 mb-6 mb-md-0">
            <CreatorApplicationInfoCard
              @is-successful="initCreatorApplicationIsSuccessful = true"
            ></CreatorApplicationInfoCard>
          </v-col>
          <v-scroll-x-transition leave-absolute>
            <v-col
              v-if="initCreatorApplicationIsSuccessful"
              cols="12"
              md="6"
              xl="5"
              class="pl-md-6"
            >
              <UploadDocumentCard
                id="upload-document-card"
                @is-successful="submitApplicationIsSuccessful = true"
              ></UploadDocumentCard>
            </v-col>
          </v-scroll-x-transition>
        </v-row>
      </v-scroll-x-transition>
    </div>
  </PageLayout>
</template>

<script>
  import PageLayout from '@/views/layout/PageLayout';
  import RegisterAccountCard from '@/views/components/signup/step-1/RegisterAccountCard';
  import SetMobileNumberCard from '@/views/components/signup/step-1/SetMobileNumberCard';
  import CreatorApplicationInfoCard from '@/views/components/signup/step-2/CreatorApplicationInfoCard';
  import UploadDocumentCard from '@/views/components/signup/step-2/UploadDocumentCard';
  import { ROUTE_NAME } from '@/core/constants';
  import { jwtHelper, i18nHelper, uiHelper } from '@/core/utils';

  export default {
    name: 'SignUp',
    beforeRouteLeave(to, from, next) {
      if (
        this.accountRegistrationIsSuccessful &&
        !this.submitApplicationIsSuccessful
      ) {
        let answer = window.confirm(
          i18nHelper.getMessage(
            'message.doYouReallyWantToLeaveYouHaveUnsavedChanges'
          )
        );
        if (answer) {
          jwtHelper.removeToken();
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    },
    components: {
      PageLayout,
      RegisterAccountCard,
      SetMobileNumberCard,
      CreatorApplicationInfoCard,
      UploadDocumentCard
    },
    mounted() {
      localStorage.removeItem('resendCount');
    },
    data: () => ({
      accountRegistrationIsSuccessful: false,
      setContactNumberIsSuccessful: false,
      initCreatorApplicationIsSuccessful: false,
      submitApplicationIsSuccessful: false,
      phoneNumber: ''
    }),
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
    watch: {
      submitApplicationIsSuccessful() {
        if (this.submitApplicationIsSuccessful) {
          this.$router.push(ROUTE_NAME.REGISTER_COMPLETE);
          jwtHelper.removeToken();
        }
      },
      accountRegistrationIsSuccessful() {
        if (this.accountRegistrationIsSuccessful) {
          this.setPreventUnsavedDataDialog();
        }
      },
      initCreatorApplicationIsSuccessful(newValue) {
        if (newValue && this.isMobile) {
          this.$nextTick(() => {
            this.$vuetify.goTo('#upload-document-card');
          });
        }
      }
    },
    methods: {
      setPreventUnsavedDataDialog() {
        let operatingSystem = uiHelper.getOperatingSystem();
        let eventName = operatingSystem == 'IOS' ? 'pagehide' : 'beforeunload';

        window.addEventListener(eventName, function (event) {
          // If you prevent default behavior in Mozilla Firefox prompt will always be shown
          event.preventDefault();

          // Chrome requires returnValue to be set
          event.returnValue = '';
        });
      },
      updateContactNumber(value) {
        this.phoneNumber = value;
      }
    }
  };
</script>

<style lang="scss"></style>
