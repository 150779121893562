<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="disabled ? '' : on">
        <AppFormField
          v-model="address"
          type="textarea"
          :solo="true"
          :flat="true"
          :rounded="true"
          :rules="formValidation.requiredRules()"
          :disabled="true"
          :rows="4"
          color="secondary"
          backgroundColor="text"
          class="signup-address-field__app-form-field"
          :class="{ isDisabled: disabled }"
        >
        </AppFormField>
      </div>
    </template>
    <v-card>
      <v-toolbar dark color="secondary">
        <v-btn icon dark @click="closeMap">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          $t('label.inputYourAddressByTheSearchBox')
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialog = false">
            {{ $t('label.confirm') }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <GooglePlaceSearch
        v-if="dialog"
        ref="map"
        @input="input"
      ></GooglePlaceSearch>
    </v-card>
  </v-dialog>
</template>

<script>
  import { formValidationHelper } from '@/core/utils';
  import GooglePlaceSearch from '@/views/components/GooglePlaceSearch.vue';

  export default {
    name: 'SignupAddressField',
    components: {
      GooglePlaceSearch
    },
    props: {
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      formValidation: formValidationHelper,
      address: null,
      addressObj: null,
      dialog: false
    }),
    methods: {
      input(value) {
        this.addressObj = value;
        this.address = value.address;
        this.$emit('input', value);
      },
      closeMap() {
        this.dialog = false;
        this.address = null;
      }
    }
  };
</script>

<style lang="scss">
  .signup-address-field__app-form-field {
    textarea {
      color: #000000 !important;
    }
    .v-messages__message {
      color: var(--v-error-base) !important;
    }
    &.isDisabled {
      cursor: default !important;
      textarea {
        color: rgba(0, 0, 0, 0.38) !important;
      }
    }
  }
</style>
