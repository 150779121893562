<template>
  <SignupCard :title="$t('label.step2')" class="creator-application-info-card">
    <v-form
      v-model="isCreatorApplicationFormValid"
      ref="creatorApplicationForm"
    >
      <template v-for="(i, index) in formFields">
        <div :key="index">
          <div class="surface--text text-capitalize px-6 mb-2">
            {{ $t(`label.${i.label}`) }}
          </div>
          <SignupAddressField
            v-model="creatorApplicationObj[i.key]"
            v-if="i.type == 'address'"
            :disabled="initCreatorApplicationIsSuccessful"
          ></SignupAddressField>
          <AppFormField
            v-else
            v-model="creatorApplicationObj[i.key]"
            :type="i.type"
            :solo="true"
            :flat="true"
            :rounded="true"
            :rules="i.rules"
            :disabled="initCreatorApplicationIsSuccessful"
            :rows="i.rows"
            :prefix="i.prefix"
            :class="i.class"
            color="secondary"
            backgroundColor="text"
          >
            <template v-slot:prepend-inner>
              <img
                v-if="i.key == 'contactNumber'"
                src="/assets/image/flag_malaysia.png"
                alt="flag-malaysia"
              />
            </template>
          </AppFormField>
        </div>
      </template>

      <div class="px-4">
        <v-checkbox
          v-model="checkboxAgreement"
          :rules="formValidation.requiredRules()"
          :disabled="initCreatorApplicationIsSuccessful"
          dark
          color="primary"
          class="creator-application-info-card__checkbox"
          on-icon="mdi-check-circle"
          off-icon="mdi-check-circle"
        >
          <template v-slot:label>
            <div
              class="surface--text"
              @click.stop
              v-html="$t('message.agreementMessage')"
            ></div>
          </template>
        </v-checkbox>
      </div>
      <div class="text-center my-8">
        <SignupButton
          :incompleteInput="creatorApplicationFormIncomplete"
          :isSuccessful="initCreatorApplicationIsSuccessful"
          @click="registerAction"
        >
          {{ $t('label.next') }}
        </SignupButton>
      </div>
    </v-form>
  </SignupCard>
</template>

<script>
  import common from '@/core/mixin/common.mixin';
  import SignupCard from '@/views/components/signup/SignupCard';
  import SignupButton from '@/views/components/signup/SignupButton';
  import { ROUTE_NAME, SHARED } from '@/core/constants';
  import { formValidationHelper, i18nHelper } from '@/core/utils';
  import {
    CREATOR_APPLICATION_INIT_APPLICATION,
    CREATOR_APPLICATION_INITIAL_INIT_APPLICATION_STATE
  } from '@/core/store/creator-application.module';
  import SignupAddressField from '@/views/components/signup/SignupAddressField.vue';

  export default {
    name: 'CreatorApplicationInfoCard',
    components: {
      SignupCard,
      SignupButton,
      SignupAddressField
    },
    mixins: [common],
    data: () => ({
      formValidation: formValidationHelper,
      creatorApplicationObj: {
        storeName: null,
        creatorName: null,
        contactNumber: null,
        email: null,
        businessPlaceName: null,
        businessAddressObj: null,
        remark: null
      },
      checkboxAgreement: false,
      formFields: [
        {
          key: 'storeName',
          rules: formValidationHelper.requiredRules(),
          label: 'businessName'
        },
        {
          key: 'creatorName',
          rules: formValidationHelper.requiredRules(),
          label: 'picFullName'
        },
        {
          key: 'contactNumber',
          rules: formValidationHelper.mobileNumberRules('my'),
          label: 'contactNumber',
          prefix: '+' + SHARED.COUNTRY_CALLING_CODE_MY,
          class: 'creator-application-info-card__contact-number-field'
        },
        {
          key: 'email',
          rules: formValidationHelper.emailRules(),
          label: 'emailAddress'
        },
        {
          key: 'businessPlaceName',
          rules: formValidationHelper.requiredRules(),
          label: 'businessPlaceName'
        },
        {
          key: 'businessAddressObj',
          label: 'businessAddress',
          type: 'address'
        },
        {
          key: 'remark',
          label: 'remark'
        }
      ],
      initCreatorApplicationIsSuccessful: false,
      isCreatorApplicationFormValid: false,
      dialog: false
    }),
    computed: {
      initApplicationComplete() {
        return this.$store.state.creatorApplication.initApplication.complete;
      },
      creatorApplicationFormIncomplete() {
        return this.initCreatorApplicationIsSuccessful
          ? false
          : !this.isCreatorApplicationFormValid;
      },
      contactNumberPrefix() {
        return '+' + SHARED.COUNTRY_CALLING_CODE_MY;
      }
    },
    watch: {
      initApplicationComplete() {
        let response = this.$store.state.creatorApplication.initApplication;

        if (response.complete) {
          this.initApplicationCompleteAction(response);
        }
      }
    },
    mounted() {
      this.insertHref();
    },
    methods: {
      initApplicationCompleteAction(response) {
        if (response.code == 0) {
          this.initCreatorApplicationIsSuccessful = true;
          this.$emit('is-successful');
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage('label.initCreatorApplication');
          let description = response.message;
          let buttons = [
            {
              color: 'secondary',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ];
          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialInitApplicationState();
      },
      registerAction() {
        let data = {
          ...this.creatorApplicationObj
        };
        let addressObj = this.creatorApplicationObj.businessAddressObj;

        data.businessAddress = addressObj.address;
        data.businessAddressCity = addressObj.city;
        data.businessAddressState = addressObj.state;
        data.businessAddressPostcode = addressObj.postcode;
        data.latitude = addressObj.latitude;
        data.longitude = addressObj.longitude;
        data.contactNumber = Number(
          `${SHARED.COUNTRY_CALLING_CODE_MY}${data.contactNumber}`
        );

        if (this.$refs.creatorApplicationForm.validate()) {
          this.initApplication(data);
        }
      },
      initApplication(data) {
        this.$store.dispatch(CREATOR_APPLICATION_INIT_APPLICATION, { data });
      },
      initialInitApplicationState() {
        this.$store.dispatch(
          CREATOR_APPLICATION_INITIAL_INIT_APPLICATION_STATE
        );
      },
      insertHref() {
        let id = ['termsOfUse', 'privacyPolicy'];
        let link = {
          termsOfUse: ROUTE_NAME.TERMS_OF_USE,
          privacyPolicy: ROUTE_NAME.PRIVACY_POLICY
        };

        id.forEach((x) => {
          let element = document.getElementById(x);
          element.setAttribute(
            'href',
            `/support/${link[x]}${x == 'termsOfUse' ? '?isCreator=true' : ''}`
          );
          element.setAttribute('target', '_blank');
          element.setAttribute('class', 'surface--text');
        });
      }
    }
  };
</script>

<style lang="scss">
  .creator-application-info-card {
    .creator-application-info-card__checkbox {
      &.v-input--is-label-active {
        &.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate)
          .v-icon {
          color: var(--v-primary-base) !important;
        }
      }
      .v-input--selection-controls__input {
        margin-right: 28px !important;
        .v-icon {
          font-size: 30px !important;
        }
      }
      .v-label {
        font-size: 0.875rem !important;
        font-weight: 700 !important;
        color: var(--v-surface-base) !important;
        line-height: 16px !important;
      }
    }
    .creator-application-info-card__contact-number-field {
      .v-input__prepend-inner {
        padding-right: 20px !important;
        img {
          max-height: 30px;
        }
      }
    }
  }

  @media (max-width: 1903px) {
    .creator-application-info-card {
      .creator-application-info-card__checkbox {
        .v-label {
          font-size: 0.7rem !important;
        }
      }
    }
  }
</style>
