<template>
  <v-card
    flat
    min-height="100%"
    class="rounded-lg py-6 px-6 px-sm-10 px-md-12 sign-up__card"
  >
    <v-card-title
      class="signup-card__title surface--text font-weight-bold text-uppercase text-h5 mb-4"
      >{{ title }}
    </v-card-title>
    <slot />
  </v-card>
</template>

<script>
  export default {
    name: 'SignupCard',
    props: {
      title: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style lang="scss">
  .sign-up__card {
    background: rgb(51, 72, 86, 0.8) !important;

    .signup-card__title {
      word-break: break-word;
    }
  }
</style>
