<template>
  <SignupCard :title="$t('label.step1')">
    <v-form
      v-model="isContactNumberFormValid"
      ref="contactNumberForm"
      @submit.prevent
    >
      <div class="font-weight-bold surface--text text-caption mb-4 px-4">
        {{ $t('message.requestOTPCodeMessage', [6]) }}
      </div>
      <AppFormField
        v-model="contactNumber"
        :solo="true"
        :flat="true"
        :rounded="true"
        :rules="formValidation.mobileNumberRules('my')"
        :disabled="requestOTPIsSuccessful"
        :prefix="contactNumberPrefix"
        color="secondary"
        backgroundColor="text"
        class="verify-mobile-number__contact-number-field"
      >
        <template v-slot:prepend-inner>
          <img
            src="/assets/image/flag_malaysia.png"
            alt="flag-malaysia"
            style="max-height: 30px"
          />
        </template>
      </AppFormField>
      <div v-if="!requestOTPIsSuccessful" class="text-center my-8">
        <SignupButton
          :incompleteInput="contactNumberFormIncomplete"
          :isSuccessful="requestOTPIsSuccessful"
          @click="requestOTPAction"
        >
          {{ $t('label.requestOTP') }}
        </SignupButton>
      </div>
    </v-form>

    <v-form
      v-if="requestOTPIsSuccessful"
      v-model="isOTPFormValid"
      ref="otpForm"
      class="pt-6"
      @submit.prevent
    >
      <v-row no-gutters class="surface--text mb-4">
        <v-col cols="8" class="font-weight-bold text-caption px-4">
          {{
            $t('message.enterTheOTPSentTo', [
              contactNumberPrefix + contactNumber
            ])
          }}
        </v-col>
        <v-col
          cols="4"
          class="d-flex justify-end align-center font-weight-bold text-body-1"
        >
          <span id="timer"></span>
        </v-col>
      </v-row>
      <AppFormField
        v-model="passcode"
        :solo="true"
        :flat="true"
        :rounded="true"
        :rules="formValidation.numericRules()"
        type="password"
        color="secondary"
        backgroundColor="text"
      >
      </AppFormField>
      <div class="surface--text font-weight-bold text-caption px-4">
        {{ $t('label.didntReceiveTheOTP') }}
        <span
          class="text-uppercase text-decoration-underline"
          :class="[
            isResendDisabled
              ? 'cursor-default greyDivider--text'
              : 'cursor-pointer'
          ]"
          @click="resendAction()"
        >
          {{ $t('label.resend') }}</span
        >
      </div>
      <div class="text-center my-8">
        <SignupButton
          :incompleteInput="!isOTPFormValid"
          :isSuccessful="isSubmittingPasscode"
          @click="verifyOTPAction"
        >
          {{ $t('label.verify') }}
        </SignupButton>
      </div>
    </v-form>
  </SignupCard>
</template>

<script>
  import common from '@/core/mixin/common.mixin';
  import SignupCard from '@/views/components/signup/SignupCard';
  import SignupButton from '@/views/components/signup/SignupButton';
  import { formValidationHelper, i18nHelper } from '@/core/utils';
  import { SHARED } from '@/core/constants';
  import {
    AUTH_SET_CONTACT_NUMBER,
    AUTH_VERIFY_CONTACT_NUMBER,
    AUTH_INITIAL_SET_CONTACT_NUMBER_STATE,
    AUTH_INITIAL_VERIFY_CONTACT_NUMBER_STATE
  } from '@/core/store/auth.module';

  export default {
    name: 'SetMobileNumberCard',
    components: {
      SignupCard,
      SignupButton
    },
    mixins: [common],
    data: () => ({
      shared: SHARED,
      formValidation: formValidationHelper,
      contactNumber: null,
      requestOTPIsSuccessful: false,
      isContactNumberFormValid: false,
      passcode: null,
      resendOTPCountdown: null,
      resendOTPPendingTimeSecond: 60,
      isOTPFormValid: false,
      isSubmittingPasscode: false,
      isResendDisabled: true
    }),
    computed: {
      setContactNumberComplete() {
        return this.$store.state.auth.setContactNumber.complete;
      },
      verifyContactNumberComplete() {
        return this.$store.state.auth.verifyContactNumber.complete;
      },
      contactNumberFormIncomplete() {
        return this.requestOTPIsSuccessful
          ? false
          : !this.isContactNumberFormValid;
      },
      contactNumberPrefix() {
        return '+' + SHARED.COUNTRY_CALLING_CODE_MY;
      }
    },
    watch: {
      setContactNumberComplete() {
        let response = this.$store.state.auth.setContactNumber;

        if (response.complete) {
          this.setContactNumberCompleteAction(response);
        }
      },
      verifyContactNumberComplete() {
        let response = this.$store.state.auth.verifyContactNumber;

        if (response.complete) {
          this.verifyContactNumberCompleteAction(response);
        }
      }
    },
    methods: {
      verifyContactNumberCompleteAction(response) {
        if (response.code == 0) {
          this.$emit('is-successful');
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage('label.verifyContactNumber');
          let description = response.message;
          let buttons = [
            {
              color: 'secondary',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
                this.isSubmittingPasscode = false;
              }
            }
          ];

          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialVerifyContactNumberState();
      },
      setContactNumberCompleteAction(response) {
        if (response.code == 0) {
          this.requestOTPIsSuccessful = true;
          this.$nextTick(function () {
            this.isResendDisabled = true;
            this.setResentOTPTimer();
          });
        } else {
          let type = 'error';
          let title = i18nHelper.getMessage('label.setContactNumber');
          let description = response.message;
          let buttons = [
            {
              color: 'secondary',
              text: i18nHelper.getMessage('label.ok'),
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ];

          this.openAppDialogInfo(type, title, description, buttons);
        }

        this.initialSetContactNumberState();
      },
      setResentOTPTimer() {
        let vm = this;
        document.getElementById('timer').innerHTML = 1 + ':' + 0;

        startTimer();

        function startTimer() {
          var presentTime = document.getElementById('timer').innerHTML;
          var timeArray = presentTime.split(/[:]+/);
          var m = timeArray[0];
          var s = checkSecond(timeArray[1] - 1);
          if (s == 59) {
            m = m - 1;
          }
          if (m < 0) {
            return;
          }

          document.getElementById('timer').innerHTML = m + ':' + s;
          m = 0;
          if (m == '0' && s == '00') {
            vm.isResendDisabled = false;
          }

          setTimeout(startTimer, 1000);
        }

        function checkSecond(sec) {
          if (sec < 10 && sec >= 0) {
            sec = '0' + sec;
          } // add zero in front of numbers < 10
          if (sec < 0) {
            sec = '59';
          }
          return sec;
        }
      },
      verifyOTPAction() {
        let data = {
          contactNumber: SHARED.COUNTRY_CALLING_CODE_MY + this.contactNumber,
          passcode: this.passcode
        };

        if (this.$refs.otpForm.validate()) {
          localStorage.removeItem('resendCount');
          this.verifyContactNumber(data);
          this.isSubmittingPasscode = true;
          this.$emit(
            'contactNumber',
            SHARED.COUNTRY_CALLING_CODE_MY + this.contactNumber
          );
        }
      },
      resendAction() {
        var $resendCount = localStorage.getItem('resendCount');

        if ($resendCount == null) {
          $resendCount = 1;
        } else {
          $resendCount = parseInt($resendCount) + 1;
        }

        if ($resendCount > 5) {
          let buttons = [
            {
              text: '/assets/image/wrong.png',
              action: () => {
                this.closeAppDialogInfo();
              }
            },
            {
              text: '/assets/image/true.png',
              action: () => {
                this.closeAppDialogInfo();
                window.open(
                  'https://api.whatsapp.com/send?phone=60127790406&text=Hi+CookX+mate%2C+my+OTP+request+period+was+over.+Would+like+to+request+assistance+on+this+issue.',
                  '_blank'
                );
              }
            }
          ];

          this.openWhatsAppDialog(buttons);
        } else if (!this.isResendDisabled) {
          localStorage.setItem('resendCount', $resendCount);
          this.requestOTPAction();
        }
      },
      requestOTPAction() {
        let data = {
          contactNumber: Number(
            `${SHARED.COUNTRY_CALLING_CODE_MY}${this.contactNumber}`
          )
        };
        if (this.$refs.contactNumberForm.validate()) {
          this.setContactNumber(data);
        }
      },
      setContactNumber(data) {
        this.$store.dispatch(AUTH_SET_CONTACT_NUMBER, { data });
      },
      verifyContactNumber(data) {
        this.$store.dispatch(AUTH_VERIFY_CONTACT_NUMBER, { data });
      },
      initialSetContactNumberState() {
        this.$store.dispatch(AUTH_INITIAL_SET_CONTACT_NUMBER_STATE);
      },
      initialVerifyContactNumberState() {
        this.$store.dispatch(AUTH_INITIAL_VERIFY_CONTACT_NUMBER_STATE);
      }
    }
  };
</script>

<style lang="scss">
  .verify-mobile-number__contact-number-field {
    .v-input__prepend-inner {
      padding-right: 20px !important;
    }
  }
</style>
