import { render, staticRenderFns } from "./UploadDocumentCard.vue?vue&type=template&id=060c1cc0"
import script from "./UploadDocumentCard.vue?vue&type=script&lang=js"
export * from "./UploadDocumentCard.vue?vue&type=script&lang=js"
import style0 from "./UploadDocumentCard.vue?vue&type=style&index=0&id=060c1cc0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.5_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6._4puljzws6ajj4bjmyv75shkawy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports