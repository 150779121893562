<template>
  <v-btn
    large
    rounded
    :disabled="incompleteInput || isSuccessful"
    color="primary"
    class="font-weight-bold px-8 secondary--text signup-button"
    :class="[
      { 'signup-button__disabled--incompleteInput': incompleteInput },
      { 'signup-button__disabled--isSuccessful': isSuccessful }
    ]"
    @click="$emit('click')"
  >
    <slot />
  </v-btn>
</template>

<script>
  export default {
    name: 'SignupButton',
    props: {
      incompleteInput: {
        type: Boolean,
        default: false
      },
      isSuccessful: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss">
  .signup-button {
    &.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
      &.signup-button__disabled--incompleteInput {
        opacity: 0.3;
        color: var(--v-secondary-base) !important;
        background-color: var(--v-surface-base) !important;
      }
      &.signup-button__disabled--isSuccessful {
        opacity: 0.3;
        color: var(--v-secondary-base) !important;
        background-color: var(--v-primary-base) !important;
      }
    }
  }
</style>
